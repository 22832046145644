import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    powderWhite: '#FFFDF9',
    primary: '#ee6900',
    secondary: '#ec7d47',
    tertiary: '#374f55',
    onyx: '#3f5a5e',
    disabled: 'rgb(222,222,226)',
  },
  fonts: ['sans-serif', 'Raleway'],
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
};

function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default Theme;
